import "./bootstrap.css";

import Router from "./Router/Router";

const App = () => (
    <div className="App">
        <Router />
    </div>
);

export default App;
