const menuStyles = {
    bmBurgerButton: {
        position: "absolute",
        width: "2rem",
        height: "2rem",
        right: "0.5rem",
        top: "0.5rem"
    },
    bmBurgerBars: {
        background: "#0d6efd"
    },
    bmBurgerBarsHover: {
        background: "#a90000"
    },
    bmCrossButton: {
        height: "24px",
        width: "24px"
    },
    bmCross: {
        background: "#bdc3c7"
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        top: "0",
        width: "10rem"
    },
    bmMenu: {
        background: "#373a47",
        padding: "2.5em 0em 0",
        fontSize: "1.15em"
    },
    bmMorphShape: {
        fill: "#373a47"
    },
    bmItemList: {
        color: "#b8b7ad",
        padding: "0.8em",
        position: "relative"
    },
    bmItem: {
        display: "inline-block"
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
        top: "0"
    }
};

export default menuStyles;