import { useCallback, useEffect, useRef } from "react";

function useEffectWithInterval<T>(
    onChange: (state: T | undefined) => void,
    deps: React.DependencyList,
    interval = 500
) {
    const timeout = useRef<any>(null);

    const clearTimer = useCallback(() => {
        timeout.current && clearTimeout(timeout.current);
    }, []);

    useEffect(() => {
        clearTimer();

        timeout.current = global.setTimeout(onChange, interval);

        return () => {
            clearTimer();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval, onChange, clearTimer, ...deps]);
}

export default useEffectWithInterval;
