import Nav from "../Nav/Nav";
import styles from "./page.module.scss";

interface IPage {
    showNav: boolean;
    children?: React.ReactNode;
}

const Page = (props: IPage) => (
    <div className={styles["page"]}>
        <div className={styles["header"]}>{props.showNav && <Nav></Nav>}</div>
        <div id={"page-wrap"} className={styles["content"]}>
            {props.children}
        </div>
    </div>
);

export default Page;
